.quem-somos {
    max-width: 100%;
    overflow-x: hidden;
    /* Previne overflow horizontal */
    padding: 0 1rem;
    /* Adiciona um pequeno padding nas laterais */
}

.quem-somos-container,
.quem-somos-container-valores,
.diferencial-container,
.time-container {
    max-width: 1200px;
    /* ou o valor que corresponde à largura máxima do seu layout */
    margin: 0 auto;
    width: 100%;
}

.quem-somos-container {
    margin-top: 4rem;
}

.lista-valores-container {
    margin: 0 auto;
    align-items: center;
    max-width: 1200px;
    width: fit-content;
    overflow: hidden;
    position: relative;
}

.quem-somos-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.quem-somos-container-texto {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.quem-somos-container-imagem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titulo-quem-somos {
    line-height: 1;
    font-size: 4rem;
    font-weight: 700;
    color: var(--primary-color);
}

.texto-quem-somos {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color);
}

.quem-somos-container-valores {
    max-width: 1200px;
    margin: 4rem auto;
    padding: 0 1rem;
}

.titulo-valores {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--primary-color);
}

.lista-valores {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    justify-content: center;
    gap: 1rem;
}

.valor {
    flex-shrink: 0;
    font-size: 16px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    border-radius: 5px;
    color: black;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: .3s;
    user-select: none;
}

.valor:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: .3s;
    color: white;
    background-color: var(--primary-color);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.diferencial-titulo {
    font-weight: 900;
    font-size: 18px;
}

.diferencial-container {
    text-align: center;
}

.diferencial-texto {
    font-size: 18px;
}

.diferencial-itens {
    margin: 4rem 0;
    padding: 0 ;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
}

.diferencial {
    display: grid;
    gap: 1rem;
    max-width: 100%;
    object-fit: cover;
    width: 180px;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.diferencial:hover {
    transform: translateY(-10px);
}

.diferencial img {
    max-width: 100%;
    transition: transform 0.3s ease;
}

.diferencial:hover img {
    transform: scale(1.1);
}

.diferencial p {
    transition: color 0.3s ease;
}

.time-container {
    background-color: #f8f9fa;
    padding: 4rem 2rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.time-titulo {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}

.time-content-vaga {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.time-info {
    flex: 1;
}

.time-info p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

.time-beneficios {
    list-style-type: none;
    padding: 0;
}

.time-beneficios li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
}

.time-beneficios li::before {
    content: '✓';
    color: var(--primary-color);
    position: absolute;
    left: 0;
}

.time-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.time-form input,
.time-form textarea {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}

.time-form textarea {
    height: 150px;
    resize: vertical;
}

.submit-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: 0.3s;
}

.submit-btn:hover {
    transition: 0.3s;
    filter: brightness(0.9);
}

@media (max-width: 900px) {
    .diferencial-itens {
        grid-template-columns: 1fr 1fr;
    }

    .time-content-vaga {
        flex-direction: column;
    }

    .quem-somos-container-valores {
        display: none;
    }

    .quem-somos-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 600px) {
    .quem-somos {
        margin: 2rem auto;
    }

    .titulo-quem-somos {
        font-size: 2.5rem;
    }



    .time-titulo {
        font-size: 2rem;
    }

    .time-form input,
    .time-form textarea {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .quem-somos {
        gap: 3rem;
    }

    .titulo-quem-somos {
        font-size: 2rem;
    }

    .texto-quem-somos {
        font-size: 0.9rem;
    }

    .quem-somos-container-valores {
        display: block;
    }

    .lista-valores {
        gap: 1rem;
    }

    .valor {
        padding: 8px 16px;
        font-size: 14px;
        white-space: nowrap;
    }

    .diferencial-texto {
        font-size: 16px;
    }

    .diferencial {
        width: 100%;
    }

    .time-info p {
        font-size: 1rem;
    }

    .time-beneficios li {
        font-size: 0.9rem;
    }
}

@media (max-width: 1200px) {
    .quem-somos {
        margin: 3rem auto;
        padding: 0 2rem;
    }

    .quem-somos-container {
        gap: 3rem;
    }

    .diferencial-itens {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media (max-width: 900px) {
    .quem-somos-container {
        grid-template-columns: 1fr;
    }

    .quem-somos-container-imagem img {
        max-width: 100%;
        height: auto;
    }

    .time-content-vaga {
        flex-direction: column;
    }

    .titulo-quem-somos {
        font-size: 3rem;
    }

    .quem-somos-container-valores {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .quem-somos-container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .quem-somos-container img {
        max-width: 100%;
        height: auto;
    }

    .titulo-quem-somos {
        font-size: 2.5rem;
    }

    .texto-quem-somos {
        font-size: 0.9rem;
    }

    .diferencial-itens {
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
    }

    .diferencial {
        width: 100%;
    }

    .time-content-vaga {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .quem-somos {
        padding: 0 1rem;
    }

    .titulo-quem-somos {
        font-size: 2rem;
    }


    .valor {
        font-size: 14px;
        padding: 8px 12px;
    }

    .time-form input,
    .time-form textarea {
        font-size: 0.9rem;
        max-width: 95%;
    }
    .time-container{
        padding: 0;
    }

    .submit-btn {
        font-size: 1rem;
        padding: 0.8rem;
    }
}