@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

:root {
    --primary-color: #1d5796;
    --secondary-color: #92c020;
    --text-color: #333333;
    --background-color: #ffffff;
    --menu-bg-color: #f8f9fa;
    --transition-speed: 0.3s;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.header-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.logo img {
    height: 40px;
    width: auto;
}

.header-nav {
    display: flex;
    align-items: center;
}

.header-nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.header-nav ul li a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: var(--text-color);
    transition: color var(--transition-speed);
}

.header-nav ul li a:hover,
.header-nav ul li a.active {
    color: var(--primary-color);
}

.contato {
    display: flex;
    align-items: center;
    gap: 20px;
}

.dropbtn {
    font-weight: 500;
}

.contato span {
    font-weight: 600;
    color: var(--primary-color);
    cursor: pointer;
}

.contato button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color var(--transition-speed);
}

.contato button:hover {
    filter: brightness(1.1);
}

.hamburger {
    display: none;
}

@media screen and (max-width: 1024px) {
    .header-nav ul {
        gap: 15px;
    }

    .header-nav ul li a {
        font-size: 14px;
    }

    .contato span {
        font-size: 14px;
    }

    .contato button {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .header-header {
        padding: 15px;
    }

    .hamburger {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 1000;
    }

    .hamburger svg {
        width: 30px;
        height: 30px;
        fill: var(--text-color);
        transition: fill var(--transition-speed);
    }

    .hamburger.active svg {
        fill: var(--primary-color);
    }

    .nav-container {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100vh;
        background-color: var(--background-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: right var(--transition-speed) ease-in-out;
        z-index: 999;
        max-height: calc(40vh + 100px);
        min-height: fit-content;


    }

    .nav-container.active {
        right: 0;
        padding: 40px 0;
        max-height: calc(40vh + 100px);
        min-height: fit-content;
    }

    .header-nav ul {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .header-nav ul li a {
        font-size: 20px;
    }

    .contato {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .contato span {
        margin-bottom: 15px;
    }

    .contato button {
        width: 200px;
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        height: 50px;
    }

    .contato button {
        width: 180px;
    }
}

.desktop-only {
    display: none;
}

@media screen and (max-width: 768px) {

    .contato {
        display: none;
    }

    .desktop-only {
        display: flex;
    }

}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
}

.dropbtn:hover {
    transition: .3s;
    color: #1d5796;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--menu-bg-color);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
}

.dropdown-content a {
    color: var(--text-color);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color var(--transition-speed);
}

.dropdown-content a:hover {
    background-color: #e9ecef;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media screen and (max-width: 768px) {
    .dropdown-content {
        position: static;
        display: none;
        width: 100%;
        box-shadow: none;
    }

    .dropdown:hover .dropdown-content {
        display: none;
    }

    .dropdown.active .dropdown-content {
        display: block;
    }
}

.chat-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 350px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.close-chat {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.chat-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.jornada-content>.chat-container {
    align-self: center;
}

.telefone-container {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    color: var(--primary-color);
}

.telefone-container>a {
    color: var(--primary-color);

}

.telefone-container>a:visited {
    color: var(--primary-color);
}

.planos-header {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}