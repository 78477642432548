.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    grid-template-rows: 0fr 0fr 0fr 1fr;
    grid-template-columns: auto;
    display: grid;
    position: relative;
    background-color: var(--background-color);
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 1500px;
    min-height: 700px;
    max-height: 95vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: start;
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.3s;
}

.modal-close:hover {
    color: var(--secondary-color);
}

.modal-title {
    text-align: center;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
    font-size: 1.8rem;
}

.modal-subtitle {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.tab {
    padding: 0.5rem 1rem;
    border: none;
    background-color: var(--border-color);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: var(--text-color);
    font-weight: bold;
    border-radius: 25px;
    margin: 0 0.5rem;
    font-size: 0.9rem;
}

.tab.active {
    background-color: var(--primary-color);
    color: white;
}

.planos-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-self: center;
    justify-self: center;
    flex-wrap: wrap;
}

.plano-card {
    flex: 1 1 250px;
    /* Alterado para permitir crescimento e encolhimento */
    max-width: 300px;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 2px solid var(--border-color);
    border-radius: 12px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
    min-width: 250px;
    /* Define uma largura mínima para os cards */
    max-width: 300px;
    /* Define uma largura máxima para os cards */
    height: fit-content;
}

.plano-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.plano-card.destaque {
    border-color: var(--primary-color);
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.plano-header {
    margin-bottom: 0.5rem;
    position: relative;
}

.destaque-tag {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: var(--secondary-color);
    color: white;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 20px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.plano-card h3 {
    font-size: 1.3rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.preco {
    font-size: 2rem;
    font-weight: bold;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.preco span {
    font-size: 0.9rem;
    color: var(--text-color);
}

.preco-anual {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.economia {
    background-color: rgba(32, 185, 176, 0.1);
    color: var(--primary-color);
    padding: 0.3rem;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}

.lista-recursos {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
    text-align: left;
}

.lista-recursos {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.2rem;
    font-size: 0.9rem;
}

.lista-recursos::before {
    content: "\2713";
    /* Unicode checkmark */
    position: absolute;
    left: 0;
    color: var(--primary-color);
    font-weight: bold;
}

/* Add this new rule */
.lista-recursos i {
    margin-right: 5px;
    color: var(--primary-color);
}

.escolher-plano {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.6rem 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-weight: bold;
    width: 100%;
    font-size: 0.9rem;
}

.escolher-plano:hover {
    transform: translateY(-2px);
}

.plano-card.destaque .escolher-plano {
    background-color: var(--secondary-color);
}

.plano-card.destaque .escolher-plano:hover {
    filter: brightness(0.9);
}

@media (max-width: 1024px) {
    .planos-container {
        justify-content: space-around;
    }

    .plano-card {
        flex-basis: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
    }
}

@media (max-width: 768px) {
    .modal-content {
        padding: 1.5rem;
        min-height: auto;
        max-height: 98vh;
    }

    .planos-container {
        flex-direction: column;
        align-items: stretch;
        /* Alterado para stretch */
    }

    .plano-card {
        flex-basis: 100%;
        max-width: 100%;
    }

    .plano-card.destaque {
        transform: scale(1);
        order: -1;
    }

}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.user-data-form-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.form-content {
    display: flex;
    gap: 2rem;
}

.form-left {
    flex: 1;
}

.form-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.user-data-form-container h3 {
    text-align: left;
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.form-subtitle {
    color: var(--text-color);
    margin-bottom: 2rem;
    font-size: 1rem;
}

.user-data-form {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.form-row {
    display: flex;
    gap: 1rem;
}

.form-group {
    position: relative;
    flex: 1;
}

.input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
}

.user-data-form input,
.user-data-form select,
.user-data-form textarea {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.user-data-form input {
    width: calc(100% - 64px);
}

.user-data-form input:focus,
.user-data-form select:focus,
.user-data-form textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.1);
}

.user-data-form textarea {
    resize: vertical;
    min-height: 100px;
}

.form-info {
    font-size: 0.9rem;
    color: var(--text-color);
}

.form-info a {
    color: var(--primary-color);
    text-decoration: none;
}

.form-info a:hover {
    text-decoration: underline;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
}

.btn-primary,
.btn-secondary {
    padding: 1rem 2rem;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.btn-primary {
    background-color: var(--primary-color);
    color: white;
}

.btn-secondary {
    background-color: var(--border-color);
    color: var(--text-color);
}

.btn-primary:hover,
.btn-secondary:hover {
    transform: translateY(-2px);
}

.plan-summary {
    background-color: var(--background-color);
    border-radius: 8px;
    margin-bottom: 2rem;
    padding: 20px;
}

.plan-summary h4 {
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.plan-name {
    font-size: 1.2rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
}

.plan-type {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.plan-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--secondary-color);
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    /* Centraliza o texto */
}

.plan-price span:first-child {
    font-size: 1.4rem;
    /* Tamanho do texto para o valor mensal */
    color: var(--primary-color);
    /* Cor do valor mensal */
}

.plan-savings {
    font-size: 1rem;
    color: var(--text-color);
    margin-top: 0.5rem;
    background-color: rgba(32, 185, 176, 0.1);
    /* Fundo leve para destaque */
    padding: 0.5rem;
    border-radius: 5px;
    text-align: center;
    /* Centraliza o texto */
}

.plan-features {
    list-style-type: none;
    padding: 0;
}

.plan-features li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.plan-features li svg {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.marketing-content h4 {
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.marketing-content ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

.marketing-content li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;
}

.marketing-content li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--primary-color);
    font-weight: bold;
}

.testimonial {
    font-style: italic;
    background-color: var(--background-color);
    padding: 1rem;
    border-radius: 8px;
    position: relative;
}

.testimonial::before {
    content: "";
    font-size: 3rem;
    position: absolute;
    top: -10px;
    left: 5px;
    color: var(--primary-color);
    opacity: 0.2;
}

@media (max-width: 768px) {
    .form-content {
        flex-direction: column-reverse;
    }

    .form-row {
        flex-direction: column;
    }

    .form-buttons {
        flex-direction: column;
        gap: 1rem;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
    }
}

.desconto {
    background-color: rgba(255, 0, 0, 0.1);
    color: rgb(163, 0, 0);
    padding: 0.3rem;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}


/* Estilização da barra de rolagem */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
}



/* Para Firefox */
* {
    scrollbar-color: var(--primary-color);
}

/* Suavizar a transição da barra de rolagem */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    transition: all 0.3s ease;
}

.textareaplanos {
    padding: 0;
}

.parcelasAnual {
    margin: 0;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.adicionalItem {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 10px;
    background-color: #f9f9f9;
    /* Fundo mais claro para destaque */
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 1rem;
    /* Espaçamento entre os itens */
}

.adicionalItem:hover {
    transform: translateY(-3px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.beneficioAdicional {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: all 1s ease;
    margin-top: 10px;
    /* Espaçamento acima dos benefícios */

}

.certificadoDigital:hover {
    cursor: pointer;
}

.beneficioAdicional:hover {
    cursor: pointer;
}

.infoItemAdicional {
    margin: 0px 1px 4px 10px;
    vertical-align: middle;
    text-align: center;
    align-self: center;
}

.recomendado {
    padding: 5px 10px;
    font-size: 12px;
    background-color: hsla(120, 71%, 64%, 0.600);
    border: solid 1px hsla(120, 71%, 64%, 1);
    width: fit-content;
    border-radius: 10px;
    font-weight: 600;
}

.implantacaoPreco {
    margin: 0;
    margin-top: 20px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

.nomeAdicional {
    margin: 1rem 0;
    font-weight: 600;
    font-size: 18px;
}

.btn-item-certificado {
    padding: 10px 15px;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 0.625em;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.btn-item-certificado:hover {
    color: black;
}

.btn-item-certificado:after {
    content: "";
    background: white;
    position: absolute;
    z-index: -1;
    left: -20%;
    right: -20%;
    top: 0;
    bottom: 0;
    transform: skewX(-45deg) scale(0, 1);
    transition: all 0.5s;
}

.btn-item-certificado:hover:after {
    transform: skewX(-45deg) scale(1, 1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

/* Modal de Sucesso */
.success-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
  padding: 20px;
}

.success-modal {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  border-radius: 30px;
  padding: 40px;
  width: 70%;
  max-height: 90vh;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  animation: modalPopIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

@keyframes modalPopIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.success-icon {
  margin-bottom: 20px;
}

.rocket-icon {
  font-size: 60px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rocketFloat 3s ease-in-out infinite;
}

@keyframes rocketFloat {
  0%, 100% { transform: translateY(0) rotate(-5deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
}

.success-modal h2 {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 800;
}

.welcome-message {
  font-size: 1.3rem;
  color: #2c3e50;
  margin-bottom: 30px;
  line-height: 1.6;
}

.celebration-badge {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  padding: 15px 30px;
  border-radius: 50px;
  display: inline-flex;
  gap: 15px;
  box-shadow: 0 10px 20px rgba(253, 160, 133, 0.2);
}

.plan-highlight {
  font-weight: 800;
  color: #fff;
  font-size: 1.2rem;
}

.plan-type {
  color: #fff;
  font-weight: 500;
}

.timeline {
  margin: 30px 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.timeline-item {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  text-align: left;
  animation: slideIn 0.5s ease-out forwards;
  opacity: 0;
}

.timeline-item:nth-child(1) { animation-delay: 0.2s; }
.timeline-item:nth-child(2) { animation-delay: 0.4s; }
.timeline-item:nth-child(3) { animation-delay: 0.6s; }
.timeline-item:nth-child(4) { animation-delay: 0.8s; }

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.timeline-icon {
  background: var(--primary-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
}

.timeline-content h4 {
  color: var(--primary-color);
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.timeline-content p {
  color: #666;
  margin: 0;
}

.guarantee-section {
  margin: 30px 0;
}

.guarantee-badge {
  background: linear-gradient(135deg, #00b09b 0%, #96c93d 100%);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  box-shadow: 0 10px 20px rgba(0, 176, 155, 0.2);
}

.shield-icon {
  font-size: 1.5rem;
}

.action-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.success-modal-button {
  margin: 0 auto;
  padding: 15px 40px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: none;
}

.success-modal-button.primary {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
  box-shadow: 0 10px 20px rgba(var(--primary-color-rgb), 0.3);
}

.success-modal-button.secondary {
  background: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  text-decoration: none;
}

.success-modal-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(var(--primary-color-rgb), 0.4);
}

.testimonial-snippet {
  margin-top: 40px;
  color: #666;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.quote-icon {
  color: var(--primary-color);
  opacity: 0.5;
}

@media (max-height: 800px) {
  .success-modal {
    padding: 30px;
  }

  .rocket-icon {
    font-size: 50px;
  }

  .success-modal h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .welcome-message {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .timeline-item {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .success-modal {
    width: 95%;
    padding: 25px;
    max-height: 85vh;
  }

  .action-buttons {
    flex-direction: column;
    gap: 15px;
  }
}

/* Adicione isso para prevenir scroll quando o modal estiver aberto */
body.modal-open {
  overflow: hidden;
}

/* Estilização da barra de rolagem */
.success-modal::-webkit-scrollbar {
  width: 8px;
}

.success-modal::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.success-modal::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
.error-message{
    color: red;
}