.smart-footer {
    background: var(--primary-color);
    color: #fff;
    padding: 4rem 2rem 2rem;
    font-family: 'Arial', sans-serif;
    position: relative;

    left: 0;
    font-size: 1.2rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.footer-logo {
    flex: 1;
    max-width: 300px;
}

.footer-logo img {
    max-width: 150px;
    height: auto;
    margin-bottom: 1rem;
}

.footer-logo p {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.footer-links-container {
    flex: 2;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.footer-links {
    flex: 1;
}

.footer-links-title {
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 0.5rem;
}

.footer-links a, .comercarFooter {
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.footer-links a:hover,.comercarFooter:hover {
    color: #00a8ff;
    cursor: pointer;
}

.footer-social {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.footer-social a {
    color: #fff;
    font-size: 1.5rem;
    transition: transform 0.3s ease, color 0.3s ease;
}

.footer-social a:hover {
    color: #00a8ff;
    transform: translateY(-3px);
}

.footer-bottom {
    max-width: 1200px;
    margin: 2rem auto 0;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}

@media (max-width: 768px) {

    .footer-content,
    .footer-links-container {
        flex-direction: column;
        text-align: center;
    }

    .footer-links a, .comercarFooter {
        font-size: 1.3rem;
    }

    .footer-logo {
        max-width: 100%;
        text-align: center;
        margin-bottom: 2rem;
    }

    .footer-social {
        justify-content: center;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }
}

/* Animação de entrada */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.footer-logo,
.footer-links,
.footer-bottom {
    animation: fadeInUp 0.5s ease-out forwards;
}

.footer-links:nth-child(2) {
    animation-delay: 0.2s;
}

.footer-links:nth-child(3) {
    animation-delay: 0.4s;
}

.footer-bottom {
    animation-delay: 0.6s;
}

#endereco {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
}
.politica-footer{
    color: white;
}
.politica-footer:visited{
    color: white;
}