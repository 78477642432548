:root {
    --primary-color: #1d5796;
    --secondary-color: #92c020;
    --text-color: #000000;
    --text-color-light: #575757;
    --background-color: #ffffff;
    --shadow-color: rgba(0, 0, 0, 0.12);
}

body {
    font-family: Inter, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.home {
    max-width: 1200px;
    margin: 0 auto;
}

.inicial-home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    align-items: center;
    margin: 5rem 0;
    justify-content: space-between;
    align-items: start;
}

.container-home {
    display: grid;
    height: 100%;
    max-width: 540px;
    justify-items: start;
    align-items: start;
    gap: 0;
}

.titulo-home {
    margin: 0;
    font-size: clamp(3rem, 5vw, 3.6rem);
    font-weight: 700;
    /* margin-bottom: 1rem; */
    line-height: 1.2;
}

.titulo-home span {
    color: var(--primary-color);
}

.subtitulo {
    font-size: clamp(1rem, 1.8vw, 1.2rem);
    color: var(--text-color-light);
    margin-bottom: 2rem;
    max-width: 100%;
    align-self: end;
}

.button-planos {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border: none;
    padding: 1em 3em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    align-self: flex-end;
    position: relative;
    top: 10px;
}

.button-planos:hover {
    background-color: #739a19;
}

.container-bot {
    height: 55vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(165deg, #ffffff 0%, #f8f9fa 100%);
    border-radius: 24px;
    padding: 2.5rem;
    width: 100%;
    position: relative;
    overflow: scroll;
    box-shadow:
        0 20px 40px rgba(0, 0, 0, 0.05),
        0 1px 3px rgba(0, 0, 0, 0.05);
}

.bot-header {
    flex-shrink: 0;
    text-align: center;
    margin-bottom: 1.5rem;
}

.header-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(29, 87, 150, 0.08);
    color: var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.badge-dot {
    width: 6px;
    height: 6px;
    background: var(--primary-color);
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.chatbot-titulo {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--text-color);
    line-height: 1.3;
    max-width: 600px;
    margin: 0 auto;
}

.opcoes-wrapper {
    padding: 0.5rem;
}

.opcoes-grid {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0.5rem;
}

.opcao-card {
    height: 180px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    text-align: left;
}

.card-content {
    position: relative;
    z-index: 2;
}

.card-icon-wrapper {
    width: 48px;
    height: 48px;
    background: rgba(146, 192, 32, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
}

.card-icon {
    font-size: 1.5rem;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.card-desc {
    font-size: 0.9rem;
    color: var(--text-color-light);
    line-height: 1.5;
}

.card-hover {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top,
            rgba(29, 87, 150, 0.95),
            rgba(29, 87, 150, 0.85));
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(100%);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.opcao-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
}

.opcao-card:hover .card-hover {
    transform: translateY(0);
}

.hover-text {
    color: white;
    font-weight: 500;
}

.hover-arrow {
    color: white;
    font-size: 1.25rem;
}

/* Solução Container */
.solucao-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
}

.solucao-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(146, 192, 32, 0.1);
    color: var(--secondary-color);
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.beneficios-grid-home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    padding: 0.5rem;
}

.beneficio-card {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.04);
    height: 80px;
}

.beneficio-icon {
    width: 24px;
    height: 24px;
    background: var(--secondary-color);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.check-icon {
    width: 16px;
    height: 16px;
    fill: white;
}

.beneficio-texto {
    font-size: 0.95rem;
    color: var(--text-color);
    line-height: 1.4;
}

.solucao-footer {
    flex-shrink: 0;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background: var(--background-color);
    display: flex;
    gap: 0.75rem;
}

.cta-button {
    height: 48px;
    flex: 2;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 12px;
    padding: 0 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.cta-button:hover {
    background: var(--secondary-color);
    transform: translateY(-2px);
}

.cta-arrow {
    transition: transform 0.3s ease;
}

.cta-button:hover .cta-arrow {
    transform: translateX(4px);
}

.voltar-button {
    height: 48px;
    flex: 1;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0 1.5rem;
    color: var(--text-color);
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.voltar-button:hover {
    background: rgba(0, 0, 0, 0.03);
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .container-bot {
        padding: 1.5rem;
        height: auto;
        min-height: 580px;
    }

    .opcoes-grid,
    .beneficios-grid-home {
        grid-template-columns: 1fr;
    }

    .chatbot-titulo {
        font-size: 1.5rem;
    }

    .solucao-footer {
        flex-direction: column;
    }
}

.container-bot img {
    max-width: 60%;
    height: auto;
}

.chatbot-Titulo {
    font-size: clamp(1rem, 2vw, 1.125rem);
    display: block;
    font-size: 1.1em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.subtitulo-bot {
    font-size: clamp(0.8rem, 1.5vw, 0.9rem);
    color: var(--text-color-light);
    margin-bottom: 1.5rem;
    max-width: 90%;
    margin: 0;
}

.button-input,
.input-bot,
.titulo-input {
    font-size: clamp(0.8rem, 1.2vw, 0.9rem);
}

.form-bot {
    display: flex;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
}

.funcionalidades-info img,
.logo-cliente {
    max-width: 100%;
    height: auto;
}

.titulo-input {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.input-bot {
    border: 1px solid transparent;
    box-shadow: 3px 3px 10px #00000020;
    border-radius: 5px;
    transition: 0.3s ease-out;
}

.input-bot:focus {
    outline: 0;
    transition: 0.3s ease-in;
    border: 1px solid var(--primary-color);
}

.button-input {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s;
}

.button-input:hover {
    transition: 0.3s;
    filter: brightness(0.9);
}

.logos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 3rem;
    align-items: center;
    justify-items: center;
    margin: 5rem 0;
    padding: 0 2rem;
}

.logo-cliente {
    filter: contrast(0%);
    opacity: 0.7;
    transition: filter 0.3s, opacity 0.3s, transform 0.3s;
}

.logo-cliente:hover {
    opacity: 1;
    transform: scale(1.1);
    transition: 0.3s;
}

@media (width <=1366px) {
    body {
        zoom: 0.8;
    }
}

@media screen and (max-width: 800px) {
    .inicial-home {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .funcionalidades-info .grafico {
        display: none;
    }

    .container-bot,
    .container-home {
        max-width: 100%;
        grid-template-rows: auto;
    }
}

@media screen and (max-width: 768px) {
    .home {
        padding: 1.5rem;
    }

    .logos-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .home {
        padding: 1rem;
    }

    .Inside-App {
        margin: 0 5vw;
    }

    .logos-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }
}

.funcionalidades-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
}

.funcionalidades-info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    max-width: 600px;
}

.funcionalidades-info-content span {
    text-transform: uppercase;
    color: var(--secondary-color);
    padding: 0.5rem 1rem;
    background-color: rgba(147, 192, 32, 0.2);
    position: relative;
    font-size: 0.9rem;
}

.funcionalidades-info-content span::before {
    content: "";
    width: 50px;
    height: 2px;
    background-color: var(--secondary-color);
    position: absolute;
    top: 50%;
    left: calc(100% + 0.5rem);
}

.subtitulo-funcionalidades {
    font-size: clamp(1rem, 1.8vw, 1.2rem);
    color: var(--text-color-light);
}

.button-funcionalidades {
    color: #000;
    font-weight: 600;
    background-color: transparent;
    border: 3px solid #ccc;
    padding: 1em 2em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.4s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.button-funcionalidades::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--secondary-color);
    transition: 0.4s;
    z-index: -1;
}

.button-funcionalidades:hover {
    border: 3px solid var(--secondary-color);
    color: #fff;
}

.button-funcionalidades:hover::before {
    width: 100%;
}

.button-funcionalidades:active {
    transform: scale(0.95);
}

@media screen and (min-width: 768px) {
    .funcionalidades-info {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }

    .funcionalidades-info-content {
        width: 100%;
    }

    .funcionalidades-info img {
        max-width: 45%;
    }

}

.funcionalidades-info-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.titulo-funcionalidades {
    font-size: 2.8rem;
    line-height: 1;
    color: #000;
}

.vantagens-funcionalidades {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
}

.outer-circle {
    animation: 60s linear infinite rotate;
    transform-origin: center;
}

.inner-circle {
    animation: 4s ease-in-out infinite alternate pulse;
}

.card {
    animation: 6s ease-in-out infinite float;
}

.text {
    animation: 2s ease-in-out fadeIn;
}

.graph-area {
    animation: 3s ease-in-out forwards fillIn;
}

.graph-line {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: 20s ease-in-out forwards drawLine;
}

.vertical-line {
    stroke-dasharray: 126;
    stroke-dashoffset: 126;
    animation: 2s ease-in-out 3s forwards drawLine;
}

.dot {
    animation: 2s ease-in-out infinite alternate pulse;
}

.dot-inner {
    animation: 2s ease-in-out infinite alternate-reverse pulse;
}

.tooltip {
    animation: 4s ease-in-out infinite float;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fillIn {
    from {
        fill-opacity: 0;
    }

    to {
        fill-opacity: 0.4;
    }
}

@keyframes drawLine {
    to {
        stroke-dashoffset: 0;
    }
}

.bar {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
        filter 0.3s ease-in-out;
    transform-origin: bottom;
    opacity: 0.8;
}

.bar:hover {
    filter: saturate(1.5);
    animation: 0.5s ease-out forwards enhanceBar;
}

.bar:not(:hover) {
    animation: 0.5s ease-out forwards deenhanceBar;
}

@keyframes enhanceBar {
    0% {
        transform: scaleY(1);
        opacity: 0.8;
    }

    50% {
        transform: scaleY(1.03);
        opacity: 1;
    }

    75% {
        transform: scaleY(1.01);
    }

    100% {
        transform: scaleY(1.02);
        opacity: 1;
    }
}

@keyframes deenhanceBar {
    0% {
        transform: scaleY(1.02);
        opacity: 1;
        filter: saturate(1.5);
    }

    50% {
        transform: scaleY(1.01);
        opacity: 0.9;
        filter: saturate(1.25);
    }

    100% {
        transform: scaleY(1);
        opacity: 0.8;
        filter: saturate(1);
    }
}

.chart:hover .bar:not(:hover) {
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
}

.titulo-dados {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 4rem;
}

.titulo-dados span {
    color: var(--secondary-color);
}

.dados-home-container {
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

@media screen and (max-width: 1024px) {
    .dados-home-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .dados-home-container {
        grid-template-columns: 1fr 1fr;
    }
}

.titulo-depoimentos {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: 900;
    text-align: left;
}

.depoimentos-home span {
    text-transform: uppercase;
    color: var(--primary-color);
    padding: 0.5rem 1.5rem;
    background-color: #1d579618;
    position: relative;
    font-size: 0.9rem;
}

.depoimentos-home span::before {
    content: "";
    width: 50px;
    height: 2px;
    background-color: var(--primary-color);
    position: absolute;
    top: 50%;
    left: calc(100% + 0.5rem);
}

.subtitulo-depoimentos {
    width: 50%;
    font-size: 1.1rem;
}

.depoimentos {
    gap: 1rem;
    margin: 4rem 0;
    display: flex;
}

@media screen and (max-width: 768px) {
    .depoimentos {
        flex-wrap: wrap;
    }

    .subtitulo-depoimentos {
        width: 100%;
    }
}

.funcionalidades-home {
    margin: 8rem 0;
    background-color: var(--background-color);
    padding: 4rem 0;
}

.funcionalidades-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.titulo-funcionalidades2 {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.subtitulo-funcionalidades2 {
    font-size: 1.1rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto 4rem;
    color: #666;
}

.parceria-container {
    color: var(--background-color);
    background-color: #0054a5;
    border-radius: 15px 0;
    padding: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8rem 0;
}

.tagParceria {
    text-transform: uppercase;
    font-size: 1.1rem;
    position: relative;
    font-weight: 600;
}

.tagParceria::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 30%;
    width: 70px;
    height: 3px;
    background-color: var(--background-color);
}

.titulo-parceria {
    margin: 1.5rem 0;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 1.1;
    text-align: start;
    font-size: 2.5rem;
    max-width: 70%;
}

.parceria-imagem>img {
    max-width: 100%;
    object-fit: contain;
}

.parceria-form form {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
}

.parceria-form input {
    width: 15rem;
    border: none;
    border-radius: 5px;
    padding: 1rem;
}

.parceria-form button {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: 1rem;
    background-color: var(--secondary-color);
    color: var(--background-color);
    font-weight: 500;
    text-transform: uppercase;
}

.parceria-telefone {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 1rem 0;
}

.parceria-telefone a {
    color: var(--secondary-color);
    text-decoration: underline;
}

.parceria-content {
    flex: 1;
    max-width: 60%;
}

.parceria-imagem {
    flex: 0 0 35%;
    text-align: center;
}

.parceria-imagem>img {
    max-width: 100%;
    object-fit: contain;
}

@media (max-width: 1024px) {
    .parceria-container {
        padding: 2.5rem;
    }

    .titulo-parceria {
        font-size: 2.2rem;
    }
}

@media (max-width: 768px) {
    .parceria-container {
        flex-direction: column;
        padding: 2rem;
        margin: 4rem 0;
    }

    .parceria-content {
        max-width: 100%;
        text-align: center;
    }

    .tagParceria {
        display: none;
    }

    .parceria-form form {
        gap: 2rem;
    }

    .titulo-parceria {
        font-size: 1.8rem;
        max-width: 100%;
        margin: 1rem 0;
    }

    .tagParceria::before {
        content: none;
    }

    .parceria-form form {
        flex-direction: column;
        align-items: stretch;
    }

    .parceria-form input {
        width: calc(100% - 32px);
    }

    .parceria-imagem {
        display: none;
    }
}

@media (max-width: 480px) {
    .parceria-container {
        padding: 1.5rem;
        margin: 2rem 0;
    }

    .titulo-parceria {
        font-size: 1.8rem;
    }

    .tagParceria {
        font-size: 1rem;
    }

    .parceria-telefone {
        font-size: 1rem;
    }
}

.fade-enter {
    opacity: 0;
    transform: translateY(10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-done {
    justify-content: end;
    justify-self: end;
    align-items: end;
    height: 100%;
}

.opcoes-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
}

.opcao-button {
    padding: 16px 24px;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    background: white;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.opcao-button:hover {
    background: var(--primary-color);
    color: white;
    transform: translateY(-2px);
}

.solucao-container {
    text-align: left;
    padding: 24px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.beneficios-lista {
    margin: 16px 0;
    padding-left: 20px;
}

.beneficios-lista li {
    margin: 8px 0;
    color: #555;
}

.voltar-button {
    margin: 0 10px;
    padding: 0px 20px;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.banner_div_home {
    display: flex;
    background-image: url('../../../public/images/home_mockup.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.home_banner {
    max-width: 100%;
    object-fit: contain;
}

.solucao-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.solucao-scroll {
    flex: 1;
    overflow-y: auto;
    padding-right: 0.5rem;
    margin-right: -0.5rem;

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.solucao-scroll::-webkit-scrollbar {
    width: 4px;
}

.solucao-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.solucao-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}