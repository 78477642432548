.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    max-width: 400px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    font-family: Arial, sans-serif;
    z-index: 9999;
}

.cookie-consent-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cookie-consent h3 {
    margin: 0 0 10px 0;
    font-size: 1.2em;
    color: #333;
}

.cookie-consent p {
    margin: 0 0 15px 0;
    font-size: 0.9em;
    line-height: 1.5;
    color: #666;
}

.cookie-consent-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.cookie-consent button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
}

.cookie-consent button:hover {
    transform: translateY(-1px);
}

.cookie-consent .accept-button {
    background-color: #4CAF50;
    color: white;
}

.cookie-consent .accept-button:hover {
    background-color: #45a049;
}

.cookie-consent .decline-button {
    background-color: #f1f1f1;
    color: #333;
}

.cookie-consent .decline-button:hover {
    background-color: #e1e1e1;
}

@media (max-width: 480px) {
    .cookie-consent {
        left: 10px;
        right: 10px;
        bottom: 10px;
    }
    
    .cookie-consent-actions {
        flex-direction: column;
    }
    
    .cookie-consent button {
        width: 100%;
    }
}