@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');


body {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

.servicos-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.servicos-header {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 4rem;
}

.servicos-header h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.servicos-header p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}


.servicos-destaque {
  margin-bottom: 4rem;
}

.servicos-destaque h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.servicos-destaque-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.servico-item {
  background-color: #eee3;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.servico-item:hover {
  transform: translateY(-5px);
}

.servico-item i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.servicos-beneficios {
  margin-bottom: 4rem;
  background-color: var(--light-bg);
  padding: 3rem 0;
  border-radius: 10px;
}

.servicos-beneficios h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--primary-color);
}

.beneficios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.beneficio-item {
  display: flex;
  align-items: flex-start;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.beneficio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.beneficio-icon {
  background-color: var(--primary-color);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex-shrink: 0;
}

.beneficio-icon i {
  font-size: 1.5rem;
}

.beneficio-content h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.beneficio-content p {
  font-size: 0.9rem;
  color: var(--text-color);
}

.beneficios-cta {
  text-align: center;
  margin-top: 3rem;
}

.beneficios-cta p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.beneficios-list {
    margin: 2rem 0;
  list-style-type: none;
  padding: 0;
}

.beneficios-list li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.beneficios-list li::before {
  content: "✓";
  color: var(--primary-color);
  position: absolute;
  left: 0;
}

.beneficios-image {
  flex: 1;
}

.beneficios-image img {
  width: 100%;
  border-radius: 10px;
}

.servicos-depoimento {
  background-color: var(--light-bg);
  padding: 3rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 4rem;
}

.servicos-depoimento blockquote {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.servicos-recursos {
  margin-bottom: 4rem;
}

.servicos-recursos h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.recursos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.recurso-item {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.recurso-item:hover {
  transform: translateY(-5px);
}

.recurso-item i {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.servicos-cta {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 10px;
}

.servicos-cta h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.servicos-cta p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

/* Ajustes gerais de responsividade */
@media (max-width: 768px) {
  .servicos-container {
    padding: 1rem;
  }

  .servicos-header {
    padding: 2rem 1rem;
  }

  .servicos-header h1 {
    font-size: 2rem;
  }

  .servicos-header p {
    font-size: 1rem;
  }

  .servicos-destaque-grid,
  .beneficios-grid,
  .recursos-grid {
    grid-template-columns: 1fr;
  }

  .beneficio-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .beneficio-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .servicos-beneficios {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .servicos-header h1 {
    font-size: 2.5rem;
  }

  .servicos-cta h2 {
    font-size: 2rem;
  }

  .beneficios-grid {
    grid-template-columns: 1fr;
  }
}