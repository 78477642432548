.politica-privacidade {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 50px 50px;
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.8;
    color: #333;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.politica-privacidade h1 {
    font-size: 2.5em;
    color: var(--primary-color);
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 15px;
}

.politica-privacidade p {
    margin-bottom: 20px;
}

.politica-privacidade section {
    margin-bottom: 40px;
}

.politica-privacidade h2 {
    font-size: 1.8em;
    color: var(--secondary-color);
    margin-bottom: 20px;
    border-left: 4px solid var(--secondary-color);
    padding-left: 15px;
}

.politica-privacidade ul {
    padding-left: 30px;
}

.politica-privacidade li {
    margin-bottom: 15px;
}

.politica-privacidade .last-updated {
    font-style: italic;
    color: #666;
    text-align: right;
}

.politica-privacidade .contact-info {
    background-color: #e9e9e9;
    padding: 20px;
    border-radius: 5px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .politica-privacidade {
        padding: 15px;
    }

    .politica-privacidade h1 {
        font-size: 2em;
    }

    .politica-privacidade h2 {
        font-size: 1.5em;
    }
}