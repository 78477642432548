@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.opiniao {
    border: 1px solid #ccc;
    width: 400px; 
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 35px 4px 35px 4px;
}

.opiniao-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.opiniao.even {
    background-color: var(--primary-color);
    color: #fff;
    /* Light gray for even testimonials */
}

.opiniao.odd {
    background-color: #ffffff;
    /* White for odd testimonials */
}

.cliente-infos {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cliente-infos img {
    width: 80px;
    height: 80px;
}

.cliente-infos-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.cliente-infos-text p {
    margin: 0;
    font-size: 1.15rem;
}

.cliente-nome {
    font-weight: 900;
    word-break: keep-all;
}

.cliente-empresa {
    font-size: .9rem;
    font-weight: 700;
    color: #ccc;
}

.cliente-opiniao {
    font-size: 16px;
    height: 150px;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .opiniao {
        width: 100%; /* Ajuste para telas menores */
        height: auto; /* Altura automática para responsividade */
    }
}