.dados-home-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 15px;
    transition: 0.3s;

}



.dados-home-container:hover .dados-home-item {
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.dados-home-container .dados-home-item:hover {
    transform: translateY(-10px);
    transition: 0.3s;
    opacity: 1;
}

.dados-home-container:hover .dados-home-item:hover {
    opacity: 1;
    transition: 0.3s;

}


.dados-home-img {
    display: flex;
    justify-content: center;
    background-color: var(--primary-color);
    width: 90%;
    height: 120px;
    align-items: center;
    border-radius: 5px 15px 5px 15px;
}

.dados-home-info {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    color: #000;
    font-weight: 500;
}

.dados-home-numero {
    font-size: 1.3rem;
    font-weight: 900;
}