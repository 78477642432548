.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
  }
  
  .h1-404 {
    font-size: 120px;
    margin: 0;
    color: #343a40;
  }
  
  .h2-404 {
    font-size: 32px;
    margin: 0;
    color: #495057;
  }
  
  .p-404 {
    font-size: 18px;
    color: #6c757d;
    margin: 20px 0;
  }
  
  .home-link-404 {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .home-link-404:hover {
    background-color: #0056b3;
  }