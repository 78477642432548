@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --text-color: #34495e;
  --light-gray: #f5f6fa;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}
.madeireira-titulo-div{
  background-image: url('../../../../public/images/madeireira.jpg');
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 4rem;
}
.madeireira-titulo-div h1{
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
  color: white;
}
.madeireira-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.madeireira-header {
  text-align: center;
  margin-bottom: 3rem;

}

.madeireira-header h1 {
  font-size: 2.5rem;
  color: white;
  line-height: 1.2;
}

.madeireira-header p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
}

.madeireira-hero {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
  background-color: var(--light-gray);
  border-radius: 10px;
  overflow: hidden;
}

.hero-content {
  flex: 1;
  padding: 2rem;
}

.hero-content h2 {
  margin: 0;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.hero-image {
  max-width: 100%;
  object-fit: cover;
  width: 500px;
  height: 320px;
}
.hero-content>p{
    margin: 1rem 0;
}
.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta-button {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-color);
}

.funcionalidades-section {
  margin-bottom: 4rem;
}

.funcionalidades-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.funcionalidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.funcionalidade-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.funcionalidade-item:hover {
  transform: translateY(-5px);
}

.funcionalidade-item i {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.fluxo-caixa-section {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
  background-color: var(--light-gray);
  border-radius: 10px;
  overflow: hidden;
}

.fluxo-caixa-content {
  flex: 1;
  padding: 2rem;
}
.fluxo-caixa-content>p{
    margin: 1rem 0;
}
.fluxo-caixa-image {
  flex: 1;
  height: 300px;
}

.fluxo-caixa-image img {
  width: 100%;
  height: 90%;
  object-fit: contain;
}

.link-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #34495e;
}

.manifesto-nfe-section {
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.diferenciais-section {
  margin-bottom: 4rem;
}

.diferenciais-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.diferenciais-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.diferencial-item {
  text-align: center;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.diferencial-item i {
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.cta-section-madeireira {
  text-align: center;
  padding: 3rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 8px;
}

.cta-section-madeireira-title{
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cta-section-madeireira p {
  margin-bottom: 2rem;
}
.madeireira-header-image{
  width: 100%;
  border-radius: 10px;
  height: 200px;
  filter: brightness(0.3);
  object-fit: cover;
}
@media (max-width: 768px) {
  .madeireira-container{
    padding: 10px;
  }
  .madeireira-header h1 {
    position: static;
    font-size: 1.8rem;
  }
  .madeireira-hero,
  .fluxo-caixa-section {
    flex-direction: column;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  }
  .hero-content{
    padding: 20px;
    width: 100%;
  }
  .hero-content h2{
    font-size: 1.5rem;
  }
  .madeireira-header-image{
    margin-bottom: 1rem;
  }
  .madeireira-header p{
    display: none;
  }
  .madeireira-header h1{
    top: -300px;
  }
}
