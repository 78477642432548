@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

:root {

  --light-gray: #f8f9fa;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.comercio-container {
  max-width: 1200px;
  margin: 0 auto;
}

.comercio-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-end;
  margin-bottom: 4rem;
}

.header-content {
  display: grid;
  justify-items: start;
  align-items: center;}

.header-content h1 {
  font-size: 3.5rem;
  line-height: 1;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.header-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.header-image img{
  border-radius: 10px;
}

.cta-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.features-section {
  background-color: var(--light-gray);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 4rem;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.tab-buttons button {
  background: none;
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
  margin: 0 0.5rem;
}

.tab-buttons button.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}
.feature-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-item i {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.testimonials-section {
  margin-bottom: 4rem;
}

.testimonials-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--primary-color);
}

.cta-section {
  background-color: var(--light-gray);
  padding: 3rem;
  border-radius: 8px;
  text-align: center;
}

.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.cta-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .comercio-header {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content p {
    font-size: 1rem;
  }

  .features-grid,
  .estatisticas-grid,
  .clientes-grid {
    grid-template-columns: 1fr;
  }

  .estatisticas-grid {
    flex-direction: column;
  }

  .carousel-slide {
    flex: 0 0 100%;
  }
}



@media (min-width: 1025px) {
  .carousel-slide {
    flex: 0 0 calc(100% / 3.2);
    padding: 0 10px;
  }
}


.hero-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  margin-bottom: 4rem;
}

.hero-content h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.estatisticas-section {
  margin-bottom: 4rem;
  text-align: center;
}

.estatisticas-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.estatisticas-grid {
  margin-top: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
}

.estatistica-item h3 {
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.clientes-destaque-section {
  margin-bottom: 4rem;
}

.clientes-destaque-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.clientes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.cliente-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cliente-item img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.faq-section {
  margin-bottom: 4rem;
}

.faq-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.faq-grid {
  display: grid;
  gap: 1rem;
}

.faq-grid details {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-grid summary {
  font-weight: bold;
  cursor: pointer;
}

.carousel-section {
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  cursor: grab;
  user-select: none;
  
}

.carousel::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carousel-slide {
  flex: 0 0 calc(100% / 3.2);
  padding: 0 10px;
  pointer-events: none;
  cursor: grab;
}

.carousel-slide img {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);

  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  cursor: grab;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contato-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contato-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contato-modal-title {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.contato-modal-form-group {
  position: relative;
  margin-bottom: 1rem;
}

.contato-modal-input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.contato-modal-input,
.contato-modal-textarea {
  width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 2.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.contato-modal-textarea {
  padding-left: 0.8rem;
}

.contato-modal-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.contato-modal-btn-primary,
.contato-modal-btn-secondary {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contato-modal-btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.contato-modal-btn-primary:hover {
  background-color: var(--secondary-color);
}

.contato-modal-btn-secondary {
  background-color: #ccc;
  color: #333;
}

.contato-modal-btn-secondary:hover {
  background-color: #bbb;
}

.contato-modal-fa-spin {
  animation: contato-modal-spin 1s linear infinite;
  margin-right: 5px;
}

.contato-modal-btn-primary:disabled,
.contato-modal-btn-secondary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes contato-modal-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  
  .carousel-slide {
    flex: 0 0 calc(100% / 1.5);
    padding: 0 10px;
  }
  .header-image {
    display: none;
  }
}