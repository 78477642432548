.titulo {
    font-size: 2rem;
    max-width: 50%;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .titulo {
        max-width: 100%;
    }
}