.chat-container {
    justify-self: flex-end;
    background-color: var(--background-color);
    box-shadow: 0 10px 30px var(--shadow-color);
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    width: 100%;
    max-width: 480px;
    min-height: 526px;
    height: 100%;
    max-height: 526px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chat-header {
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.chat-message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
    animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.5rem;
}

.message-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.message-text {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    max-width: 80%;
    word-wrap: break-word;
    transition: background-color 0.3s;
}

.chat-message.user .message-text {
    background-color: var(--primary-color);
    color: white;
    justify-content: flex-end;
    align-self: flex-end;
}

.chat-message.user {
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 1rem;
    text-align: end;
}

.chat-message.bot .message-text {
    background-color: #e5e5ea;
    color: black;
    align-self: flex-start;
}

.chat-input-container {
    display: flex;
    gap: 1rem;
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 1rem;
}

.chat-input {
    flex: 1;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    box-shadow: 1px 1px 6px #0000002f;
    border-radius: 5px;
    transition: .3s ease-out
}

.chat-input:focus {
    outline: 0;
    transition: .3s ease-in;
    border: 1px solid var(--primary-color)
}

.chat-send-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.chat-send-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.chat-send-button:active {
    transform: scale(0.95);
}

.typing {
    opacity: 0.6;
    display: inline-block;
    background-color: #e5e5ea;
    color: black;
    align-self: flex-start;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    max-width: 80%;
    word-wrap: break-word;
    transition: background-color 0.3s;
}

.typing .dot {
    animation: blink 1.4s infinite both;
    display: inline-block;
    font-size: 1.5rem;
    line-height: 0;
}

.typing .dot:nth-child(1) {
    animation-delay: 0s;
}

.typing .dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing .dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

.spam-warning {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    padding: 0.75rem 1.25rem;
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
}

.chat-input:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

.chat-send-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}