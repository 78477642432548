@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.industria-container {
  font-family: 'Roboto', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
}

.industria-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.header-content {
  flex: 1;
  padding-right: 2rem;
}

.header-content h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.header-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.header-image {
  flex: 1;
}



.cta-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.vantagens-section,
.caracteristicas-section {
  margin-bottom: 4rem;
}

.vantagens-section h2,
.caracteristicas-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.vantagens-grid,
.caracteristicas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.vantagem-item,
.caracteristica-item {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.vantagem-item:hover,
.caracteristica-item:hover {
  transform: translateY(-5px);
}

.vantagem-item i,
.caracteristica-item i {
  font-size: 2rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.vantagem-item h3,
.caracteristica-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.case-study-section {
  background-color: #f8f9fa;
  padding: 3rem;
  border-radius: 8px;
  margin-bottom: 4rem;
}

.case-study-section h2 {
  font-size: 2rem;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.case-study-content {
    display: grid;
    align-items: center;
    gap: 2rem;
    align-content: space-between;
    grid-template-columns: 1fr 1fr;
}

.case-study-content img {
    max-width: 80%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.case-study-text {height: 100%;
    display: grid;}

.case-study-text h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.cta-section {
  text-align: center;
  margin-bottom: 4rem;
}

.cta-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .industria-header {
    flex-direction: column;
  }

  .header-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .case-study-content {
    grid-template-columns: 1fr;
  }

  .case-study-content img {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

.ordem-producao-section {
  background-color: #f8f9fa;
  padding: 4rem 2rem;
  margin: 4rem 0;
  
  overflow: hidden;
}

.ordem-producao-content {
  display: flex;
  align-items: center;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.ordem-producao-text {
  flex: 1;
}

.ordem-producao-text h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.ordem-producao-text p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.ordem-producao-text .cta-button {
  background-color: var(--secondary-color);
  color: white;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--secondary-color);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.ordem-producao-text .cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease;
  z-index: -1;
}

.ordem-producao-text .cta-button:hover {
  color: var(--secondary-color);
}

.ordem-producao-text .cta-button:hover::before {
  left: 0;
}

.ordem-producao-image {
  flex: 1;
}

.sistema-mockup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.sistema-header {
  background-color: #f1f3f5;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.sistema-controls {
  display: flex;
  gap: 0.5rem;
  margin-right: 1rem;
}

.sistema-controls span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color);
}

.sistema-title {
  font-size: 0.9rem;
  color: #495057;
}

.sistema-mockup img {
  width: 100%;
  display: block;
}

@media (max-width: 768px) {
  .ordem-producao-content {
    flex-direction: column;
  }

  .ordem-producao-image {
    order: -1;
    margin-bottom: 2rem;
  }
}

.header-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    padding: 10px;
    margin-right: -10px;
}

.header-image img {
    max-width: 100%;
    display: block;
    transition: transform 0.5s ease-out;
    
    margin-top: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.fade-in {
    animation: fadeSlideIn 0.5s ease-out forwards;
}

.fade-out {
    animation: fadeSlideOut 0.5s ease-out forwards;
}

@keyframes fadeSlideIn {
    from {
        opacity: 0;
        transform: translateX(5%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeSlideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-5%);
    }
}
