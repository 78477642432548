/* .blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.blog-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.blog-posts {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.blog-post {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 10px;
}

.post-date {
  color: #7f8c8d;
  font-size: 0.9em;
  margin-bottom: 15px;
}

.post-content {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .blog-container {
    padding: 10px;
  }

  .blog-post {
    padding: 15px;
  }
}
 */

 .blog-container{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
 }
 .blog-container>img{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
 }