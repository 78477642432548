.subir {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.subir button {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
}