.card-simples {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
    font-size: 0.9rem;
    transition: all 0.3s ease-in-out;
}

.card-simples:hover {
    transform: translateY(-5px);
}

.card-simples img {
    transition: all 0.5s ease-in-out;
}

.card-simples:hover img {
    transform: scale(1.1);
}

.card-simples p {
    transition: all 0.3s ease-in-out;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


.card-simples:hover p {
    color: var(--primary-color);
}