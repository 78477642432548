:root {
  --text-color: #333333;
  --border-color: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.jornada-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.jornada-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  background-color: var(--background-color);
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 10px 25px var(--shadow-color);
}

.jornada-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.jornada-progress {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.progress-step {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--border-color);
  transition: background-color 0.3s ease;
}

.progress-step.active {
  background-color: var(--primary-color);
}

.jornada-content h1 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.jornada-content h3 {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.jornada-content form {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.jornada-content input,
.jornada-content select {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.jornada-content input:focus,
.jornada-content select:focus {
  border-color: var(--primary-color);
  outline: none;
}

.btn-primary,
.btn-secondary {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

.btn-secondary {
  background-color: var(--border-color);
  color: var(--text-color);
}

.btn-secondary:hover {
  background-color: #d0d0d0;
}

.welcome-content {
  text-align: center;
}

.welcome-content h2 {
  font-size: 1.3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.welcome-content p {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .jornada-content {
    padding: 2rem;
  }

  .jornada-content h1 {
    font-size: 1.3rem;
  }

  .welcome-content h2 {
    font-size: 1.2rem;
  }

  .welcome-content p {
    font-size: 0.9rem;
  }
}