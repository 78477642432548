@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --terceiro-color: #00a0e9;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.funcionalidades-container-page {
  max-width: 1200px;
  margin: 6rem auto;
}

.funcionalidades-header-page {
  text-align: center;
  padding: 6rem 0;
  background: linear-gradient(135deg, var(--primary-color), var(--terceiro-color));
  color: white;
  border-radius: 20px;
  margin-bottom: 4rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.funcionalidades-titulo-page {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeInUp 1s ease-out;
}

.funcionalidades-subtitulo-page {
  font-size: 1.4rem;
  max-width: 600px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-out 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.funcionalidades-section-title-page {
  font-size: 2.5rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.funcionalidades-section-title-page::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

.funcionalidades-principais-section-page,
.funcionalidades-detalhadas-section-page {
  margin-bottom: 6rem;
}

.funcionalidades-principais-lista-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.funcionalidades-tag-page {
  background-color: var(--primary-color);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
}

.funcionalidades-tag-page:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--terceiro-color);
}

.funcionalidades-tag-highlight-page {
  background-color: var(--terceiro-color);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
}

.funcionalidades-tag-highlight-page:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
}

.funcionalidades-filter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.funcionalidades-category-buttons-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.funcionalidades-category-button-page {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.funcionalidades-category-button-page.funcionalidades-category-active-page,
.funcionalidades-category-button-page:hover {
  background-color: var(--primary-color);
  color: white;
}

.funcionalidades-search-input-page {
  width: 100%;
  max-width: 400px;
  padding: 0.8rem 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.funcionalidades-search-input-page:focus {
  box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.3);
}

.funcionalidades-grid-page {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.funcionalidades-card-page {
  background-color: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.funcionalidades-card-page:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.funcionalidades-card-titulo-page {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.funcionalidades-card-descricao-page {
  font-size: 1rem;
  line-height: 1.6;
}

.funcionalidades-vantagens-section-page {
  padding: 8rem 1rem;
  /* Reduzido o padding horizontal */
  overflow: hidden;
  position: relative;
}

.funcionalidades-vantagens-content-page {
  max-width: 1400px;
  margin: 0 auto;
}

.funcionalidades-vantagens-titulo-page {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 4rem;
  color: #333;
  font-weight: 300;
}

.funcionalidades-vantagens-fluxo {
  display: flex;
  justify-content: space-between;
  /* Alterado para space-between */
  align-items: stretch;
  flex-wrap: nowrap;
  /* Alterado para nowrap */
  gap: 1rem;
  /* Reduzido o gap */
  flex-wrap: wrap;

}

.funcionalidades-vantagem-modulo {
  flex: 1;
  min-width: 0;
  /* Removida a largura mínima */
  max-width: none;
  /* Removida a largura máxima */
  width: calc(25% - 0.75rem);
  /* Definida uma largura fixa */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: fit-content;
}

.funcionalidades-vantagem-conector {
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #e0e0e0, #4facfe);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.funcionalidades-vantagem-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem 1rem;
  /* Reduzido o padding */
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  width: 220px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  height: 450px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.funcionalidades-vantagem-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.funcionalidades-vantagem-icon {
  font-size: 2.5rem;
  /* Reduzido o tamanho do ícone */
  margin-bottom: 1rem;
  color: #4facfe;
  transition: all 0.3s ease;
}

.funcionalidades-vantagem-card:hover .funcionalidades-vantagem-icon {
  transform: scale(1.1);
}

.funcionalidades-vantagem-titulo {
  font-size: 1.1rem;
  /* Reduzido o tamanho da fonte */
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.funcionalidades-vantagem-descricao {
  font-size: 0.9rem;
  /* Reduzido o tamanho da fonte */
  line-height: 1.4;
  color: #666;
}

.funcionalidades-cta-section-page {
  text-align: center;
  padding: 6rem 2rem;
  border-radius: 20px;
  margin-bottom: 4rem;
  background-color: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.funcionalidades-cta-titulo-page {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.funcionalidades-cta-descricao-page {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.funcionalidades-cta-button-page {
  background-color: var(--primary-color);
  color: white;
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.funcionalidades-cta-button-page:hover {
  background-color: var(--terceiro-color);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.funcionalidades-footer-page {
  text-align: center;
  padding: 2rem 0;
  color: var(--text-color);
}

.funcionalidades-footer-texto-page {
  font-size: 1rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .funcionalidades-vantagens-fluxo {
    gap: 3rem;
  }

  .funcionalidades-vantagem-conector {
    width: 2px;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .funcionalidades-titulo-page {
    font-size: 2.3rem;
  }

  .funcionalidades-section-title-page,
  .funcionalidades-vantagens-titulo-page,
  .funcionalidades-cta-titulo-page {
    font-size: 2.2rem;
  }

  .funcionalidades-grid-page {
    grid-template-columns: 1fr;
  }

  .funcionalidades-category-buttons-page {

    align-items: stretch;
  }



  .funcionalidades-vantagens-universo {
    flex-direction: column;
    height: auto;
  }

  .funcionalidades-vantagem-planeta {
    position: relative;
    margin: 2rem 0;
    transform: none !important;
  }

  .funcionalidades-vantagens-titulo-page {
    font-size: 2rem;
  }

  .funcionalidades-vantagem-card {
    width: 100%;
    max-width: 280px;
  }
}