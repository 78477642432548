.solucoes-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem; /* Adiciona padding lateral para telas menores */
}

.solucoes-nav {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 0; /* Remover padding lateral */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Sombra mais leve */
  overflow: hidden; /* Impede que os itens vazem sob o background */
}

.solucoes-nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap; /* Evita quebra de linha em telas menores */
  overflow-x: auto; /* Permite rolagem horizontal */
  margin-left: -1rem; /* Adiciona margem negativa para empurrar os itens para a esquerda */
  margin-right: -1rem; /* Adiciona margem negativa para empurrar os itens para a direita */
  padding: 0 1rem; /* Adiciona padding lateral para evitar que os itens fiquem cortados */
  max-width: 100%; /* Garante que a lista não exceda a largura da tela */
  display: flex; /* Garante que os itens sejam exibidos em linha */
  padding-left: 1rem; /* Adiciona padding à esquerda para garantir que o primeiro item não fique cortado */
  padding-right: 1rem; /* Adiciona padding à direita para garantir que o último item não fique cortado */
  max-width: calc(100% + 20px); /* Garante que a lista não exceda a largura da tela, considerando o padding */
}

.solucoes-nav li {
  margin: 0 0.5rem; /* Reduz a margem para telas menores */
}

.solucoes-nav button {
  padding: 0.5rem;
  font-size: 0.9rem; /* Reduz o tamanho da fonte para telas menores */
  white-space: nowrap; /* Evita quebra de linha no texto do botão */
  cursor: pointer;
  font-weight: bold;
  color: #333;
  transition: color 0.3s, transform 0.3s;
  position: relative;
}

.solucoes-nav button::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #007bff;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.solucoes-nav button:hover::after,
.solucoes-nav button.active::after {
  transform: scaleX(1);
}

.solucoes-nav button:hover,
.solucoes-nav button.active {
  color: #007bff;
  transform: translateY(-2px);
}

.solucoes-content {
  margin-top: 2rem;
}

.solucao-section {
  margin-bottom: 4rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.solucao-section:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.solucoes-nav-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap; /* Permite que os itens quebrem em telas menores */
}

.solucoes-nav-item {
  margin: 5px; /* Ajusta a margem para melhor espaçamento em telas menores */
}

.solucoes-nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  transition: all 0.3s ease;
  color: #333;
  border-radius: 8px;
}

.solucoes-nav-button:hover,
.solucoes-nav-button.active {
  color: var(--terceary-color);
  transform: translateY(-3px);
}

.solucoes-nav-icon {
  font-size: 24px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.solucoes-nav-button:hover .solucoes-nav-icon,
.solucoes-nav-button.active .solucoes-nav-icon {
  transform: scale(1.2);
  color: var(--secondary-color);
}

.solucoes-nav-label {
  font-size: 12px; /* Reduz o tamanho da fonte para telas menores */
  font-family: 'Poppins', sans-serif;
}

/* Adiciona media queries para ajustes em telas menores */
@media (max-width: 768px) {
  .solucoes-nav ul {
    flex-wrap: nowrap; /* Evita quebra de linha */
    overflow-x: auto; /* Permite rolagem horizontal */
    -webkit-overflow-scrolling: touch; /* Melhora a rolagem em iOS */
    scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */
    -ms-overflow-style: none; /* Esconde a barra de rolagem no IE/Edge */
    justify-content: flex-start;
  }

  .solucoes-nav ul::-webkit-scrollbar {
    display: none; /* Esconde a barra de rolagem no Chrome/Safari */
  }

  .solucoes-nav-item {
    flex: 0 0 auto; /* Evita que os itens encolham */
  }

  .solucoes-nav button {
    font-size: 0.8rem;
  }

  .solucao-section {
    padding: 1.5rem;
  }

  .solucoes-nav-icon {
    font-size: 18px;
  }

  .solucoes-nav-label {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .solucoes-nav button {
    padding: 0.4rem;
    font-size: 0.75rem;
  }

  .solucoes-nav-icon {
    font-size: 16px;
  }

  .solucoes-nav-label {
    font-size: 10px;
  }
}
