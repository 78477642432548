@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

:root {
  --light-gray: #f8f9fa;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.agronegocio-container {
  max-width: 1200px;
  margin: 0 auto;
}

.agronegocio-header {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* text-align: center; */
  margin-bottom: 4rem;
  gap: 20px;
}

.header-content {
  max-width: 800px;
}

.header-content h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.header-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.header-image img {
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  margin-top: 2rem;
}

.cta-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.features-section {
  background-color: var(--light-gray);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 4rem;
}

.features-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-item i {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.benefits-section {
  margin-bottom: 4rem;
}

.benefits-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.benefit-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.benefit-item:hover {
  transform: translateY(-5px);
}

.benefit-item i {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.statistics-section {
  margin-bottom: 4rem;
  text-align: center;
}

.statistics-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.statistics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.statistic-item h3 {
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.faq-section {
  margin-bottom: 4rem;
}

.faq-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.faq-grid {
  display: grid;
  gap: 1rem;
}

.faq-grid details {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-grid summary {
  font-weight: bold;
  cursor: pointer;
}

.cta-section {
  background-color: var(--light-gray);
  padding: 3rem;
  border-radius: 8px;
  text-align: center;
}

.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.cta-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contato-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contato-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contato-modal-title {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.contato-modal-form-group {
  position: relative;
  margin-bottom: 1rem;
}

.contato-modal-input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.contato-modal-input,
.contato-modal-textarea {
  width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 2.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.contato-modal-textarea {
  padding-left: 2.5rem;
  resize: none;
}

.contato-modal-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.contato-modal-btn-primary,
.contato-modal-btn-secondary {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contato-modal-btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.contato-modal-btn-primary:hover {
  background-color: var(--secondary-color);
}

.contato-modal-btn-secondary {
  background-color: #ccc;
  color: #333;
}

.contato-modal-btn-secondary:hover {
  background-color: #bbb;
}

.contato-modal-fa-spin {
  animation: contato-modal-spin 1s linear infinite;
  margin-right: 5px;
}

.contato-modal-btn-primary:disabled,
.contato-modal-btn-secondary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes contato-modal-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.jornada-agro {
  padding: 4rem 0;
  background-color: var(--light-gray);
}

.jornada-agro h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 3rem;
}

.jornada-timeline {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.jornada-item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.jornada-icon {
  background-color: var(--primary-color);
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  flex-shrink: 0;
}

.jornada-content {
  flex-grow: 1;
}

.jornada-content h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.jornada-content p {
  font-size: 1rem;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .agronegocio-header {
    flex-direction: column;
    text-align: center;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content p {
    font-size: 1rem;
  }

  .features-grid,
  .benefits-grid,
  .statistics-grid {
    grid-template-columns: 1fr;
  }

  .statistics-grid {
    gap: 1rem;
  }

  .jornada-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .jornada-icon {
    margin-bottom: 1rem;
  }
}
