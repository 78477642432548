.container-time {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    justify-content: center;
    align-items: start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0rem;
}

.time-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.time-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.time-icon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.time-icon img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.time-content {
    flex: 1;
}

.titulo-time {
    margin-bottom: 1rem;
    font-size: clamp(1.25rem, 2vw, 1.5rem);
    color: #000;
}

.desc-time {
    font-size: clamp(1.1rem, 1.5vw, 1rem);
    color: var(--text-color-light);
    margin-bottom: 1.5rem;
}

.time-item a {
    display: inline-block;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
    padding: 1rem 2rem;
}

.time-item a:hover {
    color: var(--secondary-color);
}

@media screen and (max-width: 768px) {
    .container-time {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 480px) {
    .container-time {
        grid-template-columns: 1fr;
    }

    .time-item {
        padding: 1.5rem;
    }
}