.funcionalidade-card {
    background-color: var(--card-background);
    border-radius: 20px;
    box-shadow: 0 10px 30px var(--shadow-color);
    padding: 2.5rem;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.funcionalidade-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    transition: 0.5s;
}

.funcionalidade-card:hover::before {
    left: 100%;
    animation: shine 2s ease-in-out;
}

@keyframes shine {
    0% {
        left: -100%;
    }

    20% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

.funcionalidade-card:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.funcionalidade-icon-wrapper {
    color: white;
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    transition: all 0.4s ease;
    position: relative;
    z-index: 1;
}
.funcionalidade-icon-wrapper>.fas{
    font-size: 24px;
}

.funcionalidade-card:hover .funcionalidade-icon-wrapper {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(var(--primary-color-rgb), 0.6);
}

.icone-funcionalidade {
    max-width: 60%;
    height: auto;
    filter: brightness(0) invert(1);
    transition: all 0.4s ease;
}

.funcionalidade-card:hover .icone-funcionalidade {
    transform: scale(1.1) rotate(10deg);
}

.nome-funcionalidade {
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1rem 0;
    transition: all 0.3s ease;
}

.funcionalidade-card:hover .nome-funcionalidade {
    color: var(--primary-color);
}

.descricao-funcionalidade {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #666;
    margin-top: 0.75rem;
    transition: all 0.3s ease;
}

.funcionalidade-card:hover .descricao-funcionalidade {
    color: var(--text-color);
}

.funcionalidade-card.in-view {
    opacity: 1;
    transform: translateY(0);
}